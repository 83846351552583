
// thumbs-slider
.thumbs-slider {
    position: relative;
    width: 100%;
    &__img {
        width: 100%;
        padding-top: calc(800 / 1200 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
.thumbs-slider-thumbs {
    margin: 10px auto 0;
    padding: 0 var(--container-gutter);

    .swiper-container,
    .swiper-control {
        max-width: calc(var(--container) + ((var(--container-gutter) + 60px) * 2));
    }
    .swiper-container {
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: calc(var(--container-gutter) + 60px);
            height: 100%;
            background-color: #fff;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }
    .swiper-control {
        z-index: 3;
        height: auto;
    }
    .swiper-container::before,
    .swiper-container::after,
    .swiper-control {
        display: none;
    }
    .swiper-slide {
        width: calc(25% - 7.5px);
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-bottom: 4px solid var(--v-primary-base);
            -webkit-transition: all ease-out 0.5s;
            -ms-transition: all ease-out 0.5s;
            transition: all ease-out 0.5s;
            opacity: 0;
            visibility: hidden;
        }
        &-active {
            cursor: default;
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .thumbs-slider-thumbs {
        margin-top: 24px;
        padding: 0;

        .swiper-container {
            padding: 0 calc(var(--container-gutter) + 60px);
        }
        .swiper-container::before,
        .swiper-container::after,
        .swiper-control {
            display: block;
        }
        .swiper-slide {
            width: calc(25% - 18px);
        }
    }
}
