
.step-border {
    .v-card.v-sheet--outlined {
        position: relative;
        background-color: #fff !important;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            z-index: -1;
            -webkit-transform: translate(-100%, -50%);
            -ms-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);
            display: block;
            width: 68px;
            height: 4px;
            background-color: var(--v-grey-lighten4);
        }
    }
    > [class*="col"] {
        &:first-child {
            .v-card.v-sheet--outlined::before {
                display: none;
            }
        }
    }
}
@media (min-width: 1200px) {
    .step-border {
        .v-card.v-sheet--outlined {
            min-height: 224px;
        }
    }
}
@media (max-width: 1024px) {
    .step-border {
        --grid-gutter-xl: 14px;
    }
}
@media (max-width: 768px) {
    .step-border {
        --grid-gutter-xl: 10px;
    }
}
@media (max-width: 576px) {
    .step-border {
        --grid-gutter-xl: 8px;
        .v-card.v-sheet--outlined::before {
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -100%);
            -ms-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
            width: 4px;
            height: 40px;
        }
    }
    ::v-deep {
        .v-data-table {
            > .v-data-table__wrapper > table {
                min-width: 500px;
            }
        }
    }
}
