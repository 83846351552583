
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .txt-item {
            margin: 0;
            &__col-tit,
            &__col-txt {
                padding: 0;
            }
            &__col-tit {
                width: 140px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
